import React, { useState, useEffect } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';



const ShortRender = () => {
    const [dimensioneGriglia] = useState(5);
    const [isBusy, setIsBusy] = useState(false);
    const [isEstrattaQ, setIsEstrattaQ] = useState(false);

    const [tempoGioco, setTempoGioco] = useState(0)
    const [resetTempoGioco] = useState(3 * 60)

    const alfabeto = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'L'
        , 'M', 'N', 'O', 'P', 'Qu', 'R', 'S', 'T', 'U', 'V', 'Z'];
    const vocali = ['A', 'E', 'I', 'O', 'U'];
    const consonanti = ['B', 'C', 'D', 'F', 'G', 'H', 'L', 'M'
        , 'N', 'P', 'R', 'S', 'T', 'V', 'Z'];

    const [lettere, setLettere] = useState([]);

    function randomNumberInRange(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {
        console.log({ tempoGioco });
        if (tempoGioco === 0) {
            return;
        }
        const interval = setInterval(() => {
            setTempoGioco((oldValue) => {
                if (oldValue > 0) {
                    return oldValue - 1;
                }
                return 0;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, [tempoGioco]);

    function letteraCasuale() {
        const x = randomNumberInRange(0, 99)
        if (x === 50) {
            return 'Qu';
        }
        if (x % 2) {
            // Vocale
            return vocali[randomNumberInRange(0, vocali.length - 1)];
        }
        return consonanti[randomNumberInRange(0, consonanti.length - 1)];
    }


    const estraiLettere = () => {
        setIsBusy(true);
        setIsEstrattaQ(false)
        setLettere([]);
        const tmp = []
        for (let i = 0; i < dimensioneGriglia*dimensioneGriglia; ++i) {
            tmp.push({
                id: i,
                val: letteraCasuale()
            });
        }
        setLettere(tmp)
        console.log({ lettere });
        setIsBusy(false);
        setTempoGioco(resetTempoGioco);
    }

    function barraTempo() {
        const perc = 100 * tempoGioco / resetTempoGioco
        return <div>
            {
                perc < 15 ? <ProgressBar striped animated variant="danger" now={perc} label={`${tempoGioco} sec`} /> :
                    perc < 35 ? <ProgressBar striped animated variant="warning" now={perc} label={`${tempoGioco} sec`} /> :
                        <ProgressBar striped animated variant="success" now={perc} label={`${tempoGioco} sec`} />
            }
        </div>;
    }


    useEffect(() => {
        estraiLettere();
        setTempoGioco(0);
    }, [])

    if (isBusy) {
        return <h2>Attendere...</h2>;
    }

    return (
        <div className="centratutto shadow">
            <ul className="ruzzle shadow">
                {lettere.map((el) => {
                    const { id, val } = el;
                    return (
                        <li key={id} className="shadow">
                            <div className="ruzzle-item">
                                {val}
                            </div>
                        </li>
                    );
                })}
            </ul>

            <ul className="ruzzle-button">
                <li></li>
                <li>
                    {tempoGioco > 0
                        ? <div className="shadow">{barraTempo()}</div>
                        : <div className="ruzzle-finetempo-item">Tempo Scaduto!</div>
                    }
                </li>
                <li></li>
            </ul>

            <ul className="ruzzle-button">
                <li></li>
                <li>
                    <button className="ruzzle-button-item" onClick={estraiLettere}>
                        Ricomincia da capo
                    </button>
                </li>
                <li></li>
            </ul>

        </div>
    );
};

export default ShortRender;
