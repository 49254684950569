
//import Inizio from "./lezioni/3-render-condizionale/inizio/2-render-short-circuit";
import Inizio from "./ivan/ruzzle";
//import Fine from "./lezioni/1-useState/risultato/1-errore-base";

function App() {
  return (
    <div className="App">
      <section className="container text-center my-3">
        <Inizio />
      </section>
    </div>
  );
}

export default App;
